// Helpers
import * as api from '@/utils/http';

export const createJob = (payload) => {
  const endpoint = `studios/${payload.studioId}/jobs`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getJobList = (payload) => {
  const endpoint = 'jobs';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getJobListByOrganization = (payload) => {
  const endpoint = `clients/${payload.id}/jobs`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getJobTimezoneList = () => {
  const endpoint = 'utilities/time-zones';

  return api.get(endpoint);
};

export const getJobSmsTimeList = () => {
  const endpoint = 'utilities/sms-times';

  return api.get(endpoint);
};

export const getJob = (payload) => {
  const endpoint = `jobs/${payload.id}`;

  return api.get(endpoint);
};

export const getJobPriceSheets = (payload) => {
  const endpoint = `jobs/${payload.id}/price-sheets`;
  const args = { ...payload };

  delete args.id;

  return api.get(endpoint, args);
};

export const getJobUnsubscribes = (payload) => {
  const endpoint = `jobs/${payload.id}/email-unsubscribe-list`;
  const args = { ...payload };

  delete args.id;

  return api.get(endpoint, args);
};

export const getJobSubjectsSummary = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects-summary`;

  return api.get(endpoint);
};

export const updateJob = (payload) => {
  const endpoint = `jobs/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const restoreJob = (payload) => {
  const endpoint = `jobs/${payload.id}/restore`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const deleteJob = (payload) => {
  const endpoint = `jobs/${payload.id}`;

  return api.del(endpoint);
};

// Insights
export const getInsightsCustomers = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/customers`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getInsightsCustomersSummary = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/customers_summary`;

  return api.get(endpoint);
};

export const getInsightsAccessedSubjectsSummary = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/accessed_subjects_summary`;

  return api.get(endpoint);
};

export const getInsightsYearbookSubjectsSummary = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/yearbook_subjects_summary`;

  return api.get(endpoint);
};

export const getInsightsCustomersCsv = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/customers`;
  const fileName = `${payload.name}_Customers_Report_${payload.date}.csv`;
  const args = { format: 'csv' };

  return api.getBlob(endpoint, args, fileName);
};

export const getInsightsSales = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/purchases`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getInsightsSalesSummary = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/purchases_summary`;

  return api.get(endpoint);
};

export const getInsightsSalesCsv = (payload) => {
  const endpoint = `jobs/${payload.id}/insights/purchases`;
  const fileName = `${payload.name}_Sales_Report_${payload.date}.csv`;
  const args = { format: 'csv' };

  return api.getBlob(endpoint, args, fileName);
};

export const getAccessCode = () => {
  const endpoint = 'access-codes/new';

  return api.get(endpoint);
};

// Admin
export const createAdminSms = (payload) => {
  const endpoint = `jobs/${payload.jobId}/admin/send_sms`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

// Marketing Messages
export const getJobMarketingMessages = (payload) => {
  const endpoint = `jobs/${payload.jobId}/marketing-messages`;

  return api.get(endpoint);
};
