import { call, put, select, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Sentry from '@sentry/browser';

import * as types from './constants';
import * as actions from './actions';

import * as jobsApi from '@/api/jobs-api';
import * as captureQrApi from '@/api/capture-qr-api';
import * as flyersApi from '@/api/flyers-api';
import * as peopleApi from '@/api/people-api';
import * as photosApi from '@/api/photos-api';
import * as exportsApi from '@/api/exports-api';
import * as knockoutsApi from '@/api/knockouts-api';
import * as storage from '@/utils/storage';

import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';

const UPLOAD_GROUP_JOB_ID = 'upload_group_job_id';

// Jobs
function* createJobRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.createJob, payload);

    yield call(actions.createJobSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobList, payload);

    yield put(actions.getJobListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobListByOrganizationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobListByOrganization, payload);

    yield put(actions.getJobListByOrganizationSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobListByOrganizationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobTimezoneListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobTimezoneList, payload);

    yield put(actions.getJobTimezoneListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobTimezoneListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobSmsTimeListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobSmsTimeList, payload);

    yield put(actions.getJobSmsTimeListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobSmsTimeListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJob, payload);

    yield put(actions.getJobSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobError, error);

    if (callback) {
      callback(error?.response);
    }
  } finally {
    yield put(hideLoading());
  }
}

function* getJobPriceSheetsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobPriceSheets, payload);

    yield put(actions.getJobPriceSheetsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobPriceSheetsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobUnsubscribeListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobUnsubscribes, payload);

    yield put(actions.getJobUnsubscribeListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobUnsubscribeListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobSubjectsSummary(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobSubjectsSummary, payload);

    yield put(actions.getJobSubjectsSummarySuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobSubjectsSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateJobRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.updateJob, payload);

    yield call(successHandler('Job updated'), actions.updateJobSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateJobError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* restoreJobRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.restoreJob, payload);

    yield call(successHandler('Job restored'), actions.restoreJobSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.restoreJobError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.deleteJob, payload);

    yield call(successHandler('Job archived'), actions.deleteJobSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* resetJobRequest(action) {
  const {
    payload: { callback }
  } = action;

  try {
    yield put(showLoading());

    yield put(actions.resetJobSuccess());
  } finally {
    yield put(hideLoading());

    if (callback) {
      callback();
    }
  }
}

// Photos
function* createPhotoCheckDuplicateRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.createPhotoCheckDuplicate, payload);

    yield put(actions.createPhotoCheckDuplicateSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPhotoCheckDuplicateError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPhotoRequest() {
  const { jobId, queue, hold } = yield select(({ jobs }) => jobs.upload);

  let uploadGroupJobId;

  if (storage.isLocalStorageAvailable() === true) {
    // retrieve latest upload group job id from local storage
    uploadGroupJobId = yield call(storage.get, UPLOAD_GROUP_JOB_ID);
  }

  // avoid duplicate upload group calls in a short period of time (5 seconds)
  if (hold === false && uploadGroupJobId !== jobId) {
    let uploadGroupId;

    try {
      const { data } = yield call(photosApi.createUploadGroup, { jobId });

      uploadGroupId = data.id;

      // store the new upload group job id to local storage
      storage.set(UPLOAD_GROUP_JOB_ID, jobId, true);

      // keep it for 5 seconds only
      setTimeout(() => {
        storage.remove(UPLOAD_GROUP_JOB_ID);
      }, 5000);
    } catch (error) {
      console.error(error);
    }

    yield put(actions.updatePhotoDropzone({ current: 1, successful: 0, total: queue.length }));

    for (const i in queue) {
      if (queue[i]) {
        const file = queue[i];
        const startTime = Date.now();
        const upload = yield select(({ jobs }) => jobs.upload);

        if (upload.cancel) {
          yield put(actions.updatePhotoDropzone({ queue: [] }));
          break;
        }

        try {
          yield put(showLoading());

          const { data } = yield call(photosApi.createPhotoPreSign, { jobId, name: file.name, type: file.type });

          if (data.duplicate === false || upload.replace === true) {
            const tags = file?.path
              ? file.path
                  .replace(/^\//, '')
                  .split('/')
                  .slice(-2)
                  .slice(0, -1)
                  .filter((item) => !item.startsWith('__'))
              : [];
            const keywords = file.data.keywords;

            yield call(photosApi.updatePhotoS3, { url: data.put_url, file, type: file.type });
            yield call(photosApi.createPhotoFile, {
              jobId,
              name: file.name,
              groupId: uploadGroupId ? uploadGroupId : null,
              tags: upload.keywords && keywords.length ? keywords : tags,
              ...(upload.replace ? { duplicateStrategy: upload.retainAttributes ? 'replace_with_attrs' : 'replace' } : {}) // rename / replace / replace_with_attrs
            });

            yield put(
              actions.updatePhotoDropzone({
                time: [...upload.time, { duration: Date.now() - startTime, size: file.size }],
                successful: Number(upload.successful + 1)
              })
            );
          }
        } catch (error) {
          if (!upload.failed.some((failed) => failed.name === file.name)) {
            yield put(actions.updatePhotoDropzone({ failed: [...upload.failed, file] }));
          }

          Sentry.captureException(error);
          console.error(error);
        } finally {
          yield put(hideLoading());
          yield put(
            actions.updatePhotoDropzone({
              current: Number(upload.current + 1),
              queue: upload.queue.filter((item) => item !== file)
            })
          );
        }
      }
    }

    const { retry, failed } = yield select(({ jobs }) => jobs.upload);

    // Check failed uploads
    if (retry === true && failed.length) {
      yield put(actions.updatePhotoDropzone({ retry: false, failed: [], queue: failed }));
      yield put(actions.createPhoto());
    } else {
      yield put(
        actions.updatePhotoDropzone({
          hold: false,
          cancel: false,
          retry: false,
          replace: false,
          retainAttributes: false,
          keywords: true,
          processing: false,
          requesting: false
        })
      );
    }
  }
}

function* getPhotoListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.getPhotoList, payload);

    yield put(actions.getPhotoListSuccess({ ...response, reset: payload.reset, subjectId: payload.subject_id }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPhotoRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.getPhoto, payload);

    yield put(actions.getPhotoSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPhotoTypeOptionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.getPhotoTypeOptions, payload);

    yield put(actions.getPhotoTypeOptionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoTypeOptionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePhotoRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.updatePhoto, payload);

    yield call(successHandler('Photo updated'), actions.updatePhotoSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updatePhotoError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePhotosRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.updatePhotos, payload);

    yield call(successHandler('Photo(s) updated'), actions.updatePhotosSuccess, { ...response, ...payload });

    if (callback) {
      callback();
    }
  } catch (error) {
    yield call(errorHandler, actions.updatePhotosError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePhotoRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.deletePhoto, payload);

    yield call(successHandler('Photo(s) deleted'), actions.deletePhotoSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deletePhotoError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Tags
function* createTagRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.createTag, payload);

    yield call(successHandler('Tag created'), actions.createTagSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createTagError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createTagAssociationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.createTagAssociation, payload);

    yield call(successHandler('Tag(s) added to photo(s)'), actions.createTagAssociationSuccess, {
      ...payload,
      ...response
    });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createTagAssociationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getTagListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.getTagList, payload);

    yield put(actions.getTagListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getTagListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* renameTagRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.renameTag, payload);

    yield call(successHandler('Tag renamed'), actions.renameTagSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.renameTagError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* sortTagRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.sortTag, payload);

    yield call(successHandler('Tag sorted'), actions.sortTagSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.sortTagError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteTagRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.deleteTag, payload);

    yield call(successHandler('Tag deleted'), actions.deleteTagSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteTagError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteTagAssociationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(photosApi.deleteTagAssociation, payload);

    yield call(successHandler('Tag(s) removed from selected photo(s)'), actions.deleteTagAssociationSuccess, {
      ...payload,
      ...response
    });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteTagAssociationError, error);
  } finally {
    yield put(hideLoading());
  }
}

// People
function* createPeopleRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeople, payload);

    yield call(successHandler('Person added'), actions.createPeopleSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleAssociationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleAssociation, payload);

    yield call(successHandler('Photo(s) matched'), actions.createPeopleAssociationSuccess, {
      ...payload,
      ...response
    });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleAssociationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleAssociationPhotosRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleAssociationPhotos, payload);

    yield call(successHandler('Photo(s) matched'), actions.createPeopleAssociationPhotosSuccess, {
      ...payload,
      ...response
    });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleAssociationPhotosError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleCsvRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleCsv, payload);

    yield call(successHandler('CSV file created'), actions.createPeopleCsvSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleCsvError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleCsvDetailsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleCsvDetails, payload);

    yield put(actions.createPeopleCsvDetailsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleCsvDetailsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleBulkFeatureRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createBulkFeaturePhotos, payload);

    yield put(actions.createPeopleBulkFeatureSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleBulkFeatureError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleBulkYearbookSelectionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleBulkYearbookSelection, payload);

    yield put(actions.createPeopleBulkYearbookSelectionSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleBulkYearbookSelectionError, error);
    yield call(errorHandler, actions.createJobError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleYearbookSelectionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleYearbookSelection, payload);

    yield call(actions.createPeopleYearbookSelectionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleYearbookSelectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSubjectRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getSubject, payload);

    yield put(actions.getSubjectSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSubjectError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleList, payload);

    yield put(actions.getPeopleListSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSidebarPeopleListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleList, payload);

    yield put(actions.getSidebarPeopleListSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSidebarPeopleListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleFacesListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleFacesList, payload);

    yield put(actions.getPeopleFacesListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleFacesListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeoplePhotoFacesListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeoplePhotoFacesList, payload);

    yield put(actions.getPeoplePhotoFacesListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeoplePhotoFacesListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleFieldOptionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleFieldOptions, payload);

    yield put(actions.getPeopleFieldOptionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleFieldOptionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleByPhotoIdRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleByPhotoId, payload);

    yield put(actions.getPeopleByPhotoIdSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleByPhotoIdError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePeopleRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.updatePeople, payload);

    yield call(successHandler('Subject Updated'), actions.updatePeopleSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updatePeopleError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePeopleFacesRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.updatePeopleFaces, payload);

    yield call(successHandler('Face updated'), actions.updatePeopleFacesSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updatePeopleFacesError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePeopleRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.deletePeople, payload);

    yield call(successHandler('People deleted'), actions.deletePeopleSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deletePeopleError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePhotoPeopleRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.deletePhotoPeople, payload);

    yield put(actions.deletePhotoPeopleSuccess({ ...payload, ...response }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deletePhotoPeopleError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePeoplePhotoRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.deletePeoplePhoto, payload);

    yield call(successHandler('Photo(s) unmatched'), actions.deletePeoplePhotoSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deletePeoplePhotoError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleLookupConfigRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleLookupConfig, payload);

    yield call(successHandler('Lookup Configuration saved!'), actions.createPeopleLookupConfigSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleLookupConfigError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPeopleLookupValidateRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.createPeopleLookupValidate, payload);

    yield put(actions.createPeopleLookupValidateSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPeopleLookupValidateError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleLookupConfigRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleLookupConfig, payload);

    yield put(actions.getPeopleLookupConfigSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleLookupConfigError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPeopleCsvColumnConfigRequest(action) {
  const { callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(peopleApi.getPeopleCsvColumnConfig);

    yield put(actions.getPeopleCsvColumnConfigSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPeopleCsvColumnConfigError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Insights
function* getInsightsCustomersSummaryRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsCustomersSummary, payload);

    yield put(actions.getInsightsCustomersSummarySuccess(response.data));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsCustomersSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsAccessedSubjectsSummaryRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsAccessedSubjectsSummary, payload);

    yield put(actions.getInsightsAccessedSubjectsSummarySuccess(response.data));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsAccessedSubjectsSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsYearbookSubjectsSummaryRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsYearbookSubjectsSummary, payload);

    yield put(actions.getInsightsYearbookSubjectsSummarySuccess(response.data));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsYearbookSubjectsSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsCustomersRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsCustomers, payload);

    yield put(actions.getInsightsCustomersSuccess(response));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsCustomersError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsCustomersCsvRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsCustomersCsv, payload);

    yield put(actions.getInsightsCustomersCsvSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getInsightsCustomersCsvError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsSalesSummaryRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsSalesSummary, payload);

    yield put(actions.getInsightsSalesSummarySuccess(response.data));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsSalesSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsSalesRequest(action) {
  const {
    payload: { payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsSales, payload);

    yield put(actions.getInsightsSalesSuccess(response));
  } catch (error) {
    yield call(errorHandler, actions.getInsightsSalesError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getInsightsSalesCsvRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getInsightsSalesCsv, payload);

    yield put(actions.getInsightsSalesCsvSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getInsightsSalesCsvError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getAccessCodeRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getAccessCode, payload);

    yield put(actions.getAccessCodeSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getAccessCodeError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Flyers
function* createFlyerRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.createFlyer, payload);

    yield put(actions.createFlyerSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createFlyerError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createFlyerEmailRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.createFlyerEmail, payload);

    yield call(successHandler('Your email has been sent Successfully! 😊'), actions.createFlyerEmailSuccess, { ...response, payload });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createFlyerEmailError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getFlyerListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.getFlyerList, payload);

    yield put(actions.getFlyerListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getFlyerListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getFlyerRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.getFlyer, payload);

    yield put(actions.getFlyerSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getFlyerError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getFlyerPdfRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.getFlyerPdf, payload);

    yield put(actions.getFlyerPdfSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getFlyerPdfError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateFlyerRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.updateFlyer, payload);

    yield call(successHandler('Flyer updated'), actions.updateFlyerSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateFlyerError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteFlyerRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(flyersApi.deleteFlyer, payload);

    yield call(successHandler('Flyer deleted'), actions.deleteFlyerSuccess, { ...payload, ...response });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteFlyerError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Admin
function* createAdminSmsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.createAdminSms, payload);

    yield call(successHandler('Admin SMS created'), actions.createAdminSmsSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createAdminSmsError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Exports
function* createJobExportRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.createJobExport, payload);

    yield put(actions.createJobExportSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobExportError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobExportReadyRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.createJobExportReady, payload);

    yield put(actions.createJobExportReadySuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobExportReadyError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobExportPrepareRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.createJobExportPrepare, payload);

    yield put(actions.createJobExportPrepareSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobExportPrepareError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobExportAssignSubjectsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.createJobExportAssignSubjects, payload);

    yield put(actions.createJobExportAssignSubjectsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobExportAssignSubjectsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobExportListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.getJobExportList, payload);

    yield put(actions.getJobExportListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobExportListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobExportRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.getJobExport, payload);

    yield put(actions.getJobExportSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobExportError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobExportItemsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.getJobExportItems, payload);

    yield put(actions.getJobExportItemsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobExportItemsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobExportPeopleFieldOptionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.getJobExportPeopleFieldOptions, payload);

    yield put(actions.getJobExportPeopleFieldOptionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobExportPeopleFieldOptionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateJobExportRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.updateJobExport, payload);

    yield put(actions.updateJobExportSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateJobExportError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateJobExportItemRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.updateJobExportItem, payload);

    yield put(actions.updateJobExportItemSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateJobExportItemError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobExportRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.deleteJobExport, payload);

    yield put(actions.deleteJobExportSuccess({ ...payload, ...response }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobExportError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobExportItemRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(exportsApi.deleteJobExportItem, payload);

    yield put(actions.deleteJobExportItemSuccess({ ...payload, ...response }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobExportItemError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Knockouts
function* createJobKnockoutRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.createJobKnockout, payload);

    yield put(actions.createJobKnockoutSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobKnockoutError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobKnockoutReadyRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.createJobKnockoutReady, payload);

    yield put(actions.createJobKnockoutReadySuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobKnockoutReadyError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobKnockoutPrepareRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.createJobKnockoutPrepare, payload);

    yield put(actions.createJobKnockoutPrepareSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobKnockoutPrepareError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobKnockoutTransferRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.createJobKnockoutTransfer, payload);

    yield put(actions.createJobKnockoutTransferSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobKnockoutTransferError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobKnockoutListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.getJobKnockoutList, payload);

    yield put(actions.getJobKnockoutListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobKnockoutListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobKnockoutRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.getJobKnockout, payload);

    yield put(actions.getJobKnockoutSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobKnockoutError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateJobKnockoutRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.updateJobKnockout, payload);

    yield put(actions.updateJobKnockoutSuccess({ ...response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateJobKnockoutError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobKnockoutRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.deleteJobKnockout, payload);

    yield put(actions.deleteJobKnockoutSuccess({ ...payload, ...response }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobKnockoutError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobKnockoutItemRequest() {
  const { knockoutId, queue, hold } = yield select(({ jobs }) => jobs.knockoutUpload);

  if (hold === false) {
    yield put(actions.updateKnockoutDropzone({ dismiss: false, current: 1, successful: 0, total: queue.length }));

    for (const i in queue) {
      if (queue[i]) {
        const file = queue[i];
        const startTime = Date.now();
        const upload = yield select(({ jobs }) => jobs.knockoutUpload);

        if (upload.cancel) {
          yield put(actions.updateKnockoutDropzone({ queue: [] }));
          break;
        }

        try {
          yield put(showLoading());

          let response;

          if (!file.knockoutItemId) {
            const tags = file?.path
              ? file.path
                  .replace(/^\//, '')
                  .split('/')
                  .slice(-2)
                  .slice(0, -1)
                  .filter((item) => !item.startsWith('__'))
              : [];
            const keywords = file.data.keywords;

            response = yield call(knockoutsApi.createKnockoutItem, {
              knockoutId,
              name: file.name,
              type: file.type,
              tags: keywords.length > 0 ? keywords : tags
            });
          }

          if (response && response.data.duplicate && upload.replace === false) {
            if (upload.skip === false && upload.duplicated.some((duplicate) => duplicate.name === file.name) === false) {
              // Store created knockout item data into duplicate so we dont have to call
              // createKnockoutItem again if user replaces all
              Object.defineProperties(file, {
                uploadUrl: {
                  value: response.data.upload_url,
                  writable: false
                },
                knockoutItemId: {
                  value: response.data.id,
                  writable: false
                }
              });

              yield put(actions.updateKnockoutDropzone({ duplicated: [...upload.duplicated, file] }));
            }
          } else {
            yield call(knockoutsApi.updateKnockoutItemS3, { url: file.uploadUrl || (response && response.data.upload_url), file, type: file.type });

            yield call(knockoutsApi.createKnockoutItemUploaded, {
              knockoutItemId: file.knockoutItemId || (response && response.data.id)
            });

            yield put(
              actions.updateKnockoutDropzone({
                time: [...upload.time, { duration: Date.now() - startTime, size: file.size }],
                successful: Number(upload.successful + 1)
              })
            );
          }
        } catch (error) {
          if (!upload.failed.some((failed) => failed.name === file.name)) {
            yield put(actions.updateKnockoutDropzone({ failed: [...upload.failed, file] }));
          }

          console.error(error);
          Sentry.captureException(error);
        } finally {
          yield put(hideLoading());
          yield put(
            actions.updateKnockoutDropzone({
              current: Number(upload.current + 1),
              queue: upload.queue.filter((item) => item !== file)
            })
          );
        }
      }
    }

    // If upload had at least one successful item reset reviewed and uploaded props
    const upload = yield select(({ jobs }) => jobs.knockoutUpload);

    if (upload.successful > 0) {
      yield put(actions.updateJobKnockout({ knockoutId, reviewed: false, uploaded: true }));
    }

    yield put(
      actions.updateKnockoutDropzone({
        current: 0,
        total: 0,

        time: [],

        hold: false,
        cancel: false,
        replace: false,
        retainAttributes: false,
        keywords: true,
        processing: false
      })
    );
  }
}

function* getJobKnockoutItemsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.getJobKnockoutItems, payload);

    yield put(actions.getJobKnockoutItemsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobKnockoutItemsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobKnockoutItemsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(knockoutsApi.deleteJobKnockoutItems, payload);

    yield put(actions.deleteJobKnockoutItemsSuccess({ ...payload, ...response }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobKnockoutItemsError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Marketing Messages
function* getJobMarketingMessagesRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(jobsApi.getJobMarketingMessages, payload);

    yield put(actions.getJobMarketingMessagesSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobMarketingMessagesError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Capture QR
function* getJobQrSessionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.getJobQrSessions, payload);

    yield put(actions.getJobQrSessionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobQrSessionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobQrCodeBySessionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.getJobQrCodeBySession, payload);

    yield put(actions.getJobQrCodeBySessionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getJobQrCodeBySessionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobQrCodeResolutionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.createJobQrCodeResolution, payload);

    yield put(actions.createJobQrCodeResolutionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobQrCodeResolutionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteJobQrSessionPhotosRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.deleteJobQrSessionPhotos, payload);

    yield put(actions.deleteJobQrSessionPhotosSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteJobQrSessionPhotosError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobQrIgnoreErrorsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.createJobQrIgnoreErrors, payload);

    yield put(actions.createJobQrIgnoreErrorsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobQrIgnoreErrorsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createJobQrSessionMatchingRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(captureQrApi.createJobQrSessionMatching, payload);

    yield put(actions.createJobQrSessionMatchingSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createJobQrSessionMatchingError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getJobsWatcher() {
  yield all([
    takeLatest(types.CREATE_JOB_REQUEST, createJobRequest),
    takeLatest(types.GET_JOB_LIST_REQUEST, getJobListRequest),
    takeLatest(types.GET_JOB_LIST_BY_ORGANIZATION_REQUEST, getJobListByOrganizationRequest),
    takeLatest(types.GET_JOB_TIMEZONE_LIST_REQUEST, getJobTimezoneListRequest),
    takeLatest(types.GET_JOB_SMS_TIME_LIST_REQUEST, getJobSmsTimeListRequest),
    takeLatest(types.GET_JOB_REQUEST, getJobRequest),
    takeLatest(types.GET_JOB_PRICESHEETS_REQUEST, getJobPriceSheetsRequest),
    takeLatest(types.GET_JOB_UNSUBSCRIBE_LIST_REQUEST, getJobUnsubscribeListRequest),
    takeLatest(types.GET_JOB_SUBJECTS_SUMMARY_REQUEST, getJobSubjectsSummary),
    takeLatest(types.UPDATE_JOB_REQUEST, updateJobRequest),
    takeLatest(types.RESTORE_JOB_REQUEST, restoreJobRequest),
    takeLatest(types.DELETE_JOB_REQUEST, deleteJobRequest),
    takeLatest(types.RESET_JOB_REQUEST, resetJobRequest),

    takeLatest(types.CREATE_PHOTO_CHECK_DUPLICATE_REQUEST, createPhotoCheckDuplicateRequest),
    takeLatest(types.CREATE_PHOTO, createPhotoRequest),
    takeLatest(types.GET_PHOTO_LIST_REQUEST, getPhotoListRequest),
    takeLatest(types.GET_PHOTO_REQUEST, getPhotoRequest),
    takeLatest(types.GET_PHOTO_TYPE_OPTIONS_REQUEST, getPhotoTypeOptionsRequest),
    takeLatest(types.UPDATE_PHOTO_REQUEST, updatePhotoRequest),
    takeLatest(types.UPDATE_PHOTOS_REQUEST, updatePhotosRequest),
    takeLatest(types.DELETE_PHOTO_REQUEST, deletePhotoRequest),

    takeLatest(types.CREATE_TAG_REQUEST, createTagRequest),
    takeEvery(types.CREATE_TAG_ASSOCIATION_REQUEST, createTagAssociationRequest),
    takeLatest(types.GET_TAG_LIST_REQUEST, getTagListRequest),
    takeLatest(types.RENAME_TAG_REQUEST, renameTagRequest),
    takeLatest(types.SORT_TAG_REQUEST, sortTagRequest),
    takeLatest(types.DELETE_TAG_REQUEST, deleteTagRequest),
    takeLatest(types.DELETE_TAG_ASSOCIATION_REQUEST, deleteTagAssociationRequest),

    takeLatest(types.CREATE_PEOPLE_REQUEST, createPeopleRequest),
    takeLatest(types.CREATE_PEOPLE_ASSOCIATION_REQUEST, createPeopleAssociationRequest),
    takeEvery(types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_REQUEST, createPeopleAssociationPhotosRequest),
    takeLatest(types.CREATE_PEOPLE_CSV_REQUEST, createPeopleCsvRequest),
    takeLatest(types.CREATE_PEOPLE_CSV_DETAILS_REQUEST, createPeopleCsvDetailsRequest),
    takeLatest(types.CREATE_PEOPLE_BULK_FEATURE_REQUEST, createPeopleBulkFeatureRequest),
    takeEvery(types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_REQUEST, createPeopleBulkYearbookSelectionRequest),
    takeLatest(types.CREATE_PEOPLE_YEARBOOK_SELECTION_REQUEST, createPeopleYearbookSelectionRequest),
    takeEvery(types.GET_SUBJECT_REQUEST, getSubjectRequest),
    takeEvery(types.GET_PEOPLE_LIST_REQUEST, getPeopleListRequest),
    takeEvery(types.GET_SIDEBAR_PEOPLE_LIST_REQUEST, getSidebarPeopleListRequest),
    takeLatest(types.GET_PEOPLE_FACES_LIST_REQUEST, getPeopleFacesListRequest),
    takeLatest(types.GET_PEOPLE_PHOTO_FACES_LIST_REQUEST, getPeoplePhotoFacesListRequest),
    takeLatest(types.GET_PEOPLE_FIELD_OPTIONS_REQUEST, getPeopleFieldOptionsRequest),
    takeLatest(types.GET_PEOPLE_BY_PHOTO_ID_REQUEST, getPeopleByPhotoIdRequest),
    takeLatest(types.UPDATE_PEOPLE_REQUEST, updatePeopleRequest),
    takeLatest(types.UPDATE_PEOPLE_FACES_REQUEST, updatePeopleFacesRequest),
    takeLatest(types.DELETE_PEOPLE_REQUEST, deletePeopleRequest),
    takeEvery(types.DELETE_PHOTO_PEOPLE_REQUEST, deletePhotoPeopleRequest),
    takeLatest(types.DELETE_PEOPLE_PHOTO_REQUEST, deletePeoplePhotoRequest),

    takeLatest(types.CREATE_PEOPLE_LOOKUP_CONFIG_REQUEST, createPeopleLookupConfigRequest),
    takeLatest(types.CREATE_PEOPLE_LOOKUP_VALIDATE_REQUEST, createPeopleLookupValidateRequest),
    takeLatest(types.GET_PEOPLE_LOOKUP_CONFIG_REQUEST, getPeopleLookupConfigRequest),
    takeLatest(types.GET_PEOPLE_CSV_COLUMN_CONFIG_REQUEST, getPeopleCsvColumnConfigRequest),

    takeLatest(types.GET_INSIGHTS_CUSTOMERS_SUMMARY_REQUEST, getInsightsCustomersSummaryRequest),
    takeLatest(types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_REQUEST, getInsightsAccessedSubjectsSummaryRequest),
    takeLatest(types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_REQUEST, getInsightsYearbookSubjectsSummaryRequest),
    takeLatest(types.GET_INSIGHTS_CUSTOMERS_REQUEST, getInsightsCustomersRequest),
    takeLatest(types.GET_INSIGHTS_CUSTOMERS_CSV_REQUEST, getInsightsCustomersCsvRequest),
    takeLatest(types.GET_INSIGHTS_SALES_SUMMARY_REQUEST, getInsightsSalesSummaryRequest),
    takeLatest(types.GET_INSIGHTS_SALES_REQUEST, getInsightsSalesRequest),
    takeLatest(types.GET_INSIGHTS_SALES_CSV_REQUEST, getInsightsSalesCsvRequest),

    takeLatest(types.GET_ACCESS_CODE_REQUEST, getAccessCodeRequest),

    takeLatest(types.CREATE_FLYER_REQUEST, createFlyerRequest),
    takeLatest(types.CREATE_FLYER_EMAIL_REQUEST, createFlyerEmailRequest),
    takeLatest(types.GET_FLYER_LIST_REQUEST, getFlyerListRequest),
    takeLatest(types.GET_FLYER_REQUEST, getFlyerRequest),
    takeLatest(types.GET_FLYER_PDF_REQUEST, getFlyerPdfRequest),
    takeLatest(types.UPDATE_FLYER_REQUEST, updateFlyerRequest),
    takeLatest(types.DELETE_FLYER_REQUEST, deleteFlyerRequest),

    takeLatest(types.CREATE_ADMIN_SMS_REQUEST, createAdminSmsRequest),

    takeLatest(types.CREATE_JOB_EXPORT_REQUEST, createJobExportRequest),
    takeLatest(types.CREATE_JOB_EXPORT_PREPARE_REQUEST, createJobExportPrepareRequest),
    takeLatest(types.CREATE_JOB_EXPORT_READY_REQUEST, createJobExportReadyRequest),
    takeLatest(types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_REQUEST, createJobExportAssignSubjectsRequest),
    takeLatest(types.GET_JOB_EXPORT_LIST_REQUEST, getJobExportListRequest),
    takeLatest(types.GET_JOB_EXPORT_REQUEST, getJobExportRequest),
    takeLatest(types.GET_JOB_EXPORT_ITEMS_REQUEST, getJobExportItemsRequest),
    takeLatest(types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_REQUEST, getJobExportPeopleFieldOptionsRequest),
    takeLatest(types.UPDATE_JOB_EXPORT_REQUEST, updateJobExportRequest),
    takeLatest(types.UPDATE_JOB_EXPORT_ITEM_REQUEST, updateJobExportItemRequest),
    takeLatest(types.DELETE_JOB_EXPORT_REQUEST, deleteJobExportRequest),
    takeLatest(types.DELETE_JOB_EXPORT_ITEM_REQUEST, deleteJobExportItemRequest),

    takeLatest(types.CREATE_JOB_KNOCKOUT_REQUEST, createJobKnockoutRequest),
    takeLatest(types.CREATE_JOB_KNOCKOUT_READY_REQUEST, createJobKnockoutReadyRequest),
    takeLatest(types.CREATE_JOB_KNOCKOUT_PREPARE_REQUEST, createJobKnockoutPrepareRequest),
    takeLatest(types.CREATE_JOB_KNOCKOUT_TRANSFER_REQUEST, createJobKnockoutTransferRequest),
    takeLatest(types.GET_JOB_KNOCKOUT_LIST_REQUEST, getJobKnockoutListRequest),
    takeLatest(types.GET_JOB_KNOCKOUT_REQUEST, getJobKnockoutRequest),
    takeLatest(types.UPDATE_JOB_KNOCKOUT_REQUEST, updateJobKnockoutRequest),
    takeLatest(types.DELETE_JOB_KNOCKOUT_REQUEST, deleteJobKnockoutRequest),
    takeLatest(types.CREATE_JOB_KNOCKOUT_ITEM, createJobKnockoutItemRequest),
    takeLatest(types.GET_JOB_KNOCKOUT_ITEMS_REQUEST, getJobKnockoutItemsRequest),
    takeLatest(types.DELETE_JOB_KNOCKOUT_ITEMS_REQUEST, deleteJobKnockoutItemsRequest),

    takeLatest(types.GET_JOB_MARKETING_MESSAGES_REQUEST, getJobMarketingMessagesRequest),

    takeLatest(types.GET_JOB_QR_SESSIONS_REQUEST, getJobQrSessionsRequest),
    takeLatest(types.GET_JOB_QR_CODE_BY_SESSION_REQUEST, getJobQrCodeBySessionRequest),
    takeLatest(types.CREATE_JOB_QR_CODE_RESOLUTION_REQUEST, createJobQrCodeResolutionRequest),
    takeLatest(types.DELETE_JOB_QR_SESSION_PHOTOS_REQUEST, deleteJobQrSessionPhotosRequest),
    takeLatest(types.CREATE_JOB_QR_IGNORE_ERRORS_REQUEST, createJobQrIgnoreErrorsRequest),
    takeLatest(types.CREATE_JOB_QR_SESSION_MATCHING_REQUEST, createJobQrSessionMatchingRequest)
  ]);
}

export default getJobsWatcher;
