import { Component } from 'react';
import Select from 'react-select';

export default class ReprintFullRequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = { reprintReason: [], extra_info: '' };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSubmit = (requestInfo) => {
    const { extra_info, reprintReason } = this.state;

    requestInfo = {
      reason: reprintReason[0]?.value || '',
      extra_info: extra_info
    };

    this.props.onSubmit(requestInfo);
  };

  handleInputOnChange = (event) => {
    this.setState({ extra_info: event.target.value });
  };

  handleInputOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleSelectChange = (reprintReason) => {
    this.setState({ reprintReason: [{ label: reprintReason.label, value: reprintReason.value }] });
  };

  render() {
    const { reprintReasonOptions } = this.props;
    const { reprintReason } = this.state;
    const selectedReprintReason = reprintReason[0] || { label: '', value: '' };

    return (
      <aside className="modal text--left">
        <div className="modal__box">
          <header className="modal__header">
            <h2>Request Reprint</h2>
          </header>
          <main className="modal__content">
            <label>Please select the reason for your request:</label>
            <Select
              className="select mb-20"
              classNamePrefix="select"
              name="reprintReason"
              required={true}
              value={reprintReason}
              options={reprintReasonOptions}
              onChange={(reprintReason) => this.handleSelectChange(reprintReason)}
            />
            {selectedReprintReason.value === 'incorrect_image' ? (
              <aside className="panel panel--error animate">
                <p className="text--nomargin">
                  Please note we cannot accommodate an automatic reprint that requires corrections to an image, crop, or customization. For these requests,
                  please reach out to our support team via the Bubble or at <a href="mailto:support@photoday.io">support@photoday.io</a>.
                </p>
              </aside>
            ) : (
              <fieldset className="fieldset animate">
                <label>Enter any additional information:</label>
                <textarea
                  className="input--block"
                  type="text"
                  name="additional-info"
                  rows={6}
                  maxLength={244}
                  value={this.state.extra_info}
                  onChange={this.handleInputOnChange}
                />
              </fieldset>
            )}
          </main>
          <footer className="modal__footer flex end gap-10">
            <button className="button button--blue-outline" type="button" name="cancel" onClick={this.handleCancel}>
              Cancel
            </button>
            <button
              className="button"
              name="submit"
              type="button"
              onClick={this.handleSubmit}
              disabled={!selectedReprintReason || selectedReprintReason.value === 'incorrect_image'}
            >
              Submit
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}
