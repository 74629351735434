import * as types from './constants';

// Job

// Create job
export const createJob = (payload, callback) => ({
  type: types.CREATE_JOB_REQUEST,
  payload: { payload, callback }
});

export const createJobSuccess = (payload) => ({
  type: types.CREATE_JOB_SUCCESS,
  payload: { payload }
});

export const createJobError = (error) => ({
  type: types.CREATE_JOB_ERROR,
  payload: { error }
});

// Read job list
export const getJobList = (payload, callback) => ({
  type: types.GET_JOB_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobListSuccess = (payload) => ({
  type: types.GET_JOB_LIST_SUCCESS,
  payload: { payload }
});

export const getJobListError = (error) => ({
  type: types.GET_JOB_LIST_ERROR,
  payload: { error }
});

// Read job list by organizations
export const getJobListByOrganization = (payload, callback) => ({
  type: types.GET_JOB_LIST_BY_ORGANIZATION_REQUEST,
  payload: { payload, callback }
});

export const getJobListByOrganizationSuccess = (payload) => ({
  type: types.GET_JOB_LIST_BY_ORGANIZATION_SUCCESS,
  payload: { payload }
});

export const getJobListByOrganizationError = (error) => ({
  type: types.GET_JOB_LIST_BY_ORGANIZATION_ERROR,
  payload: { error }
});

// Read job timezone list
export const getJobTimezoneList = (payload, callback) => ({
  type: types.GET_JOB_TIMEZONE_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobTimezoneListSuccess = (payload) => ({
  type: types.GET_JOB_TIMEZONE_LIST_SUCCESS,
  payload: { payload }
});

export const getJobTimezoneListError = (error) => ({
  type: types.GET_JOB_TIMEZONE_LIST_ERROR,
  payload: { error }
});

// Read job sms time list
export const getJobSmsTimeList = (payload, callback) => ({
  type: types.GET_JOB_SMS_TIME_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobSmsTimeListSuccess = (payload) => ({
  type: types.GET_JOB_SMS_TIME_LIST_SUCCESS,
  payload: { payload }
});

export const getJobSmsTimeListError = (error) => ({
  type: types.GET_JOB_SMS_TIME_LIST_ERROR,
  payload: { error }
});

// Read job
export const getJob = (payload, callback) => ({
  type: types.GET_JOB_REQUEST,
  payload: { payload, callback }
});

export const getJobSuccess = (payload) => ({
  type: types.GET_JOB_SUCCESS,
  payload: { payload }
});

export const getJobError = (error) => ({
  type: types.GET_JOB_ERROR,
  payload: { error }
});

// Read job price sheets
export const getJobPriceSheets = (payload, callback) => ({
  type: types.GET_JOB_PRICESHEETS_REQUEST,
  payload: { payload, callback }
});

export const getJobPriceSheetsSuccess = (payload) => ({
  type: types.GET_JOB_PRICESHEETS_SUCCESS,
  payload: { payload }
});

export const getJobPriceSheetsError = (error) => ({
  type: types.GET_JOB_PRICESHEETS_ERROR,
  payload: { error }
});

// Read job email unsubscribe list
export const getJobUnsubscribeList = (payload, callback) => ({
  type: types.GET_JOB_UNSUBSCRIBE_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobUnsubscribeListSuccess = (payload) => ({
  type: types.GET_JOB_UNSUBSCRIBE_LIST_SUCCESS,
  payload: { payload }
});

export const getJobUnsubscribeListError = (error) => ({
  type: types.GET_JOB_UNSUBSCRIBE_LIST_ERROR,
  payload: { error }
});

// Read job subjects summary
export const getJobSubjectsSummary = (payload, callback) => ({
  type: types.GET_JOB_SUBJECTS_SUMMARY_REQUEST,
  payload: { payload, callback }
});

export const getJobSubjectsSummarySuccess = (payload) => ({
  type: types.GET_JOB_SUBJECTS_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getJobSubjectsSummaryError = (error) => ({
  type: types.GET_JOB_SUBJECTS_SUMMARY_ERROR,
  payload: { error }
});

// Update job
export const updateJob = (payload, callback) => ({
  type: types.UPDATE_JOB_REQUEST,
  payload: { payload, callback }
});

export const updateJobSuccess = (payload) => ({
  type: types.UPDATE_JOB_SUCCESS,
  payload: { payload }
});

export const updateJobError = (error) => ({
  type: types.UPDATE_JOB_ERROR,
  payload: { error }
});

// Restore job
export const restoreJob = (payload, callback) => ({
  type: types.RESTORE_JOB_REQUEST,
  payload: { payload, callback }
});

export const restoreJobSuccess = (payload) => ({
  type: types.RESTORE_JOB_SUCCESS,
  payload: { payload }
});

export const restoreJobError = (error) => ({
  type: types.RESTORE_JOB_ERROR,
  payload: { error }
});

// Delete job
export const deleteJob = (payload, callback) => ({
  type: types.DELETE_JOB_REQUEST,
  payload: { payload, callback }
});

export const deleteJobSuccess = (payload) => ({
  type: types.DELETE_JOB_SUCCESS,
  payload: { payload }
});

export const deleteJobError = (error) => ({
  type: types.DELETE_JOB_ERROR,
  payload: { error }
});

// Reset Job
export const resetJob = (callback) => ({
  type: types.RESET_JOB_REQUEST,
  payload: { callback }
});

export const resetJobSuccess = (payload) => ({
  type: types.RESET_JOB_SUCCESS,
  payload: { payload }
});

// Photos

// Create check duplicate photos
export const createPhotoCheckDuplicate = (payload, callback) => ({
  type: types.CREATE_PHOTO_CHECK_DUPLICATE_REQUEST,
  payload: { payload, callback }
});

export const createPhotoCheckDuplicateSuccess = (payload) => ({
  type: types.CREATE_PHOTO_CHECK_DUPLICATE_SUCCESS,
  payload: { payload }
});

export const createPhotoCheckDuplicateError = (error) => ({
  type: types.CREATE_PHOTO_CHECK_DUPLICATE_ERROR,
  payload: { error }
});

// Create photos
export const createPhoto = (payload, callback) => ({
  type: types.CREATE_PHOTO,
  payload: { payload, callback }
});

// Read photo list
export const getPhotoList = (payload, callback) => ({
  type: types.GET_PHOTO_LIST_REQUEST,
  payload: { payload, callback }
});

export const getPhotoListSuccess = (payload) => ({
  type: types.GET_PHOTO_LIST_SUCCESS,
  payload: { payload }
});

export const getPhotoListError = (error) => ({
  type: types.GET_PHOTO_LIST_ERROR,
  payload: { error }
});

// Read photo
export const getPhoto = (payload, callback) => ({
  type: types.GET_PHOTO_REQUEST,
  payload: { payload, callback }
});

export const getPhotoSuccess = (payload) => ({
  type: types.GET_PHOTO_SUCCESS,
  payload: { payload }
});

export const getPhotoError = (error) => ({
  type: types.GET_PHOTO_ERROR,
  payload: { error }
});

// Read photo type options
export const getPhotoTypeOptions = (payload, callback) => ({
  type: types.GET_PHOTO_TYPE_OPTIONS_REQUEST,
  payload: { payload, callback }
});

export const getPhotoTypeOptionsSuccess = (payload) => ({
  type: types.GET_PHOTO_TYPE_OPTIONS_SUCCESS,
  payload: { payload }
});

export const getPhotoTypeOptionsError = (error) => ({
  type: types.GET_PHOTO_TYPE_OPTIONS_ERROR,
  payload: { error }
});

// Update photo
export const updatePhoto = (payload, callback) => ({
  type: types.UPDATE_PHOTO_REQUEST,
  payload: { payload, callback }
});

export const updatePhotoSuccess = (payload) => ({
  type: types.UPDATE_PHOTO_SUCCESS,
  payload: { payload }
});

export const updatePhotoError = (error) => ({
  type: types.UPDATE_PHOTO_ERROR,
  payload: { error }
});

// Update photos
export const updatePhotos = (payload, callback) => ({
  type: types.UPDATE_PHOTOS_REQUEST,
  payload: { payload, callback }
});

export const updatePhotosSuccess = (payload) => ({
  type: types.UPDATE_PHOTOS_SUCCESS,
  payload: { payload }
});

export const updatePhotosError = (error) => ({
  type: types.UPDATE_PHOTOS_ERROR,
  payload: { error }
});

// Update photo gallery
export const updatePhotoGallery = (payload) => ({
  type: types.UPDATE_PHOTO_GALLERY,
  payload: { payload }
});

// Update photo trained and remove qr images
export const updatePhotoTrainedAndRemoveQrImages = (payload) => ({
  type: types.UPDATE_PHOTO_TRAINED_AND_REMOVE_QR_IMAGES,
  payload: { payload }
});

// Update photo stats
export const updatePhotoStats = (payload) => ({
  type: types.UPDATE_PHOTO_STATS,
  payload: { payload }
});

// Update photo tags
export const updatePhotoTags = (payload) => ({
  type: types.UPDATE_PHOTO_TAGS,
  payload: { payload }
});

// Update dropzone global state
export const updatePhotoDropzone = (payload) => ({
  type: types.UPDATE_PHOTO_DROPZONE,
  payload: { payload }
});

// Delete photo
export const deletePhoto = (payload, callback) => ({
  type: types.DELETE_PHOTO_REQUEST,
  payload: { payload, callback }
});

export const deletePhotoSuccess = (payload) => ({
  type: types.DELETE_PHOTO_SUCCESS,
  payload: { payload }
});

export const deletePhotoError = (error) => ({
  type: types.DELETE_PHOTO_ERROR,
  payload: { error }
});

// Set photo search parameters
export const setPhotoQueryComponent = (payload) => ({
  type: types.SET_PHOTO_QUERY_COMPONENT,
  payload: { payload }
});

// Tags

// Create tag
export const createTag = (payload, callback) => ({
  type: types.CREATE_TAG_REQUEST,
  payload: { payload, callback }
});

export const createTagSuccess = (payload) => ({
  type: types.CREATE_TAG_SUCCESS,
  payload: { payload }
});

export const createTagError = (error) => ({
  type: types.CREATE_TAG_ERROR,
  payload: { error }
});

// Create association tag
export const createTagAssociation = (payload, callback) => ({
  type: types.CREATE_TAG_ASSOCIATION_REQUEST,
  payload: { payload, callback }
});

export const createTagAssociationSuccess = (payload) => ({
  type: types.CREATE_TAG_ASSOCIATION_SUCCESS,
  payload: { payload }
});

export const createTagAssociationError = (error) => ({
  type: types.CREATE_TAG_ASSOCIATION_ERROR,
  payload: { error }
});

// Read tag list
export const getTagList = (payload, callback) => ({
  type: types.GET_TAG_LIST_REQUEST,
  payload: { payload, callback }
});

export const getTagListSuccess = (payload) => ({
  type: types.GET_TAG_LIST_SUCCESS,
  payload: { payload }
});

export const getTagListError = (error) => ({
  type: types.GET_TAG_LIST_ERROR,
  payload: { error }
});

// Rename tag
export const renameTag = (payload, callback) => ({
  type: types.RENAME_TAG_REQUEST,
  payload: { payload, callback }
});

export const renameTagSuccess = (payload) => ({
  type: types.RENAME_TAG_SUCCESS,
  payload: { payload }
});

export const renameTagError = (error) => ({
  type: types.RENAME_TAG_ERROR,
  payload: { error }
});

// Sort tag
export const sortTag = (payload, callback) => ({
  type: types.SORT_TAG_REQUEST,
  payload: { payload, callback }
});

export const sortTagSuccess = (payload) => ({
  type: types.SORT_TAG_SUCCESS,
  payload: { payload }
});

export const sortTagError = (error) => ({
  type: types.SORT_TAG_ERROR,
  payload: { error }
});

// Delete tag
export const deleteTag = (payload, callback) => ({
  type: types.DELETE_TAG_REQUEST,
  payload: { payload, callback }
});

export const deleteTagSuccess = (payload) => ({
  type: types.DELETE_TAG_SUCCESS,
  payload: { payload }
});

export const deleteTagError = (error) => ({
  type: types.DELETE_TAG_ERROR,
  payload: { error }
});

// Delete association tag
export const deleteTagAssociation = (payload, callback) => ({
  type: types.DELETE_TAG_ASSOCIATION_REQUEST,
  payload: { payload, callback }
});

export const deleteTagAssociationSuccess = (payload) => ({
  type: types.DELETE_TAG_ASSOCIATION_SUCCESS,
  payload: { payload }
});

export const deleteTagAssociationError = (error) => ({
  type: types.DELETE_TAG_ASSOCIATION_ERROR,
  payload: { error }
});

// People

// Create people
export const createPeople = (payload, callback) => ({
  type: types.CREATE_PEOPLE_REQUEST,
  payload: { payload, callback }
});

export const createPeopleSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_SUCCESS,
  payload: { payload }
});

export const createPeopleError = (error) => ({
  type: types.CREATE_PEOPLE_ERROR,
  payload: { error }
});

// Create association people photo face
export const createPeopleAssociation = (payload, callback) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_REQUEST,
  payload: { payload, callback }
});

export const createPeopleAssociationSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_SUCCESS,
  payload: { payload }
});

export const createPeopleAssociationError = (error) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_ERROR,
  payload: { error }
});

// Create association people by photo_id
export const createPeopleAssociationPhotos = (payload, callback) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_REQUEST,
  payload: { payload, callback }
});

export const createPeopleAssociationPhotosSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_SUCCESS,
  payload: { payload }
});

export const createPeopleAssociationPhotosError = (error) => ({
  type: types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_ERROR,
  payload: { error }
});

// Create people CSV
export const createPeopleCsv = (payload, callback) => ({
  type: types.CREATE_PEOPLE_CSV_REQUEST,
  payload: { payload, callback }
});

export const createPeopleCsvSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_CSV_SUCCESS,
  payload: { payload }
});

export const createPeopleCsvError = (error) => ({
  type: types.CREATE_PEOPLE_CSV_ERROR,
  payload: { error }
});

// Create people CSV details
export const createPeopleCsvDetails = (payload, callback) => ({
  type: types.CREATE_PEOPLE_CSV_DETAILS_REQUEST,
  payload: { payload, callback }
});

export const createPeopleCsvDetailsSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_CSV_DETAILS_SUCCESS,
  payload: { payload }
});

export const createPeopleCsvDetailsError = (error) => ({
  type: types.CREATE_PEOPLE_CSV_DETAILS_ERROR,
  payload: { error }
});

// Create people bulk feature
export const createPeopleBulkFeature = (payload, callback) => ({
  type: types.CREATE_PEOPLE_BULK_FEATURE_REQUEST,
  payload: { payload, callback }
});

export const createPeopleBulkFeatureSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_BULK_FEATURE_SUCCESS,
  payload: { payload }
});

export const createPeopleBulkFeatureError = (error) => ({
  type: types.CREATE_PEOPLE_BULK_FEATURE_ERROR,
  payload: { error }
});

// Create people bulk yearbook
export const createPeopleBulkYearbookSelection = (payload, callback) => ({
  type: types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_REQUEST,
  payload: { payload, callback }
});

export const createPeopleBulkYearbookSelectionSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_SUCCESS,
  payload: { payload }
});

export const createPeopleBulkYearbookSelectionError = (error) => ({
  type: types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_ERROR,
  payload: { error }
});

// Create people yearbook selection
export const createPeopleYearbookSelection = (payload, callback) => ({
  type: types.CREATE_PEOPLE_YEARBOOK_SELECTION_REQUEST,
  payload: { payload, callback }
});

export const createPeopleYearbookSelectionSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_YEARBOOK_SELECTION_SUCCESS,
  payload: { payload }
});

export const createPeopleYearbookSelectionError = (error) => ({
  type: types.CREATE_PEOPLE_YEARBOOK_SELECTION_ERROR,
  payload: { error }
});

// Read people list
export const getPeopleList = (payload, callback) => ({
  type: types.GET_PEOPLE_LIST_REQUEST,
  payload: { payload, callback }
});

export const getPeopleListSuccess = (payload) => ({
  type: types.GET_PEOPLE_LIST_SUCCESS,
  payload: { payload }
});

export const getPeopleListError = (error) => ({
  type: types.GET_PEOPLE_LIST_ERROR,
  payload: { error }
});

// Read sidebar people list
export const getSidebarPeopleList = (payload, callback) => ({
  type: types.GET_SIDEBAR_PEOPLE_LIST_REQUEST,
  payload: { payload, callback }
});

export const getSidebarPeopleListSuccess = (payload) => ({
  type: types.GET_SIDEBAR_PEOPLE_LIST_SUCCESS,
  payload: { payload }
});

export const getSidebarPeopleListError = (error) => ({
  type: types.GET_SIDEBAR_PEOPLE_LIST_ERROR,
  payload: { error }
});

// Read people faces list
export const getPeopleFacesList = (payload, callback) => ({
  type: types.GET_PEOPLE_FACES_LIST_REQUEST,
  payload: { payload, callback }
});

export const getPeopleFacesListSuccess = (payload) => ({
  type: types.GET_PEOPLE_FACES_LIST_SUCCESS,
  payload: { payload }
});

export const getPeopleFacesListError = (error) => ({
  type: types.GET_PEOPLE_FACES_LIST_ERROR,
  payload: { error }
});

// Read people photo faces list
export const getPeoplePhotoFacesList = (payload, callback) => ({
  type: types.GET_PEOPLE_PHOTO_FACES_LIST_REQUEST,
  payload: { payload, callback }
});

export const getPeoplePhotoFacesListSuccess = (payload) => ({
  type: types.GET_PEOPLE_PHOTO_FACES_LIST_SUCCESS,
  payload: { payload }
});

export const getPeoplePhotoFacesListError = (error) => ({
  type: types.GET_PEOPLE_PHOTO_FACES_LIST_ERROR,
  payload: { error }
});

// Read people field options mapping
export const getPeopleFieldOptions = (payload, callback) => ({
  type: types.GET_PEOPLE_FIELD_OPTIONS_REQUEST,
  payload: { payload, callback }
});

export const getPeopleFieldOptionsSuccess = (payload) => ({
  type: types.GET_PEOPLE_FIELD_OPTIONS_SUCCESS,
  payload: { payload }
});

export const getPeopleFieldOptionsError = (error) => ({
  type: types.GET_PEOPLE_FIELD_OPTIONS_ERROR,
  payload: { error }
});

export const getPeopleByPhotoId = (payload, callback) => ({
  type: types.GET_PEOPLE_BY_PHOTO_ID_REQUEST,
  payload: { payload, callback }
});

export const getPeopleByPhotoIdSuccess = (payload) => ({
  type: types.GET_PEOPLE_BY_PHOTO_ID_SUCCESS,
  payload: { payload }
});

export const getPeopleByPhotoIdError = (error) => ({
  type: types.GET_PEOPLE_BY_PHOTO_ID_ERROR,
  payload: { error }
});

// Read Subject
export const getSubject = (payload, callback) => ({
  type: types.GET_SUBJECT_REQUEST,
  payload: { payload, callback }
});

export const getSubjectSuccess = (payload) => ({
  type: types.GET_SUBJECT_SUCCESS,
  payload: { payload }
});

export const getSubjectError = (error) => ({
  type: types.GET_SUBJECT_ERROR,
  payload: { error }
});

// Update people
export const updatePeople = (payload, callback) => ({
  type: types.UPDATE_PEOPLE_REQUEST,
  payload: { payload, callback }
});

export const updatePeopleSuccess = (payload) => ({
  type: types.UPDATE_PEOPLE_SUCCESS,
  payload: { payload }
});

export const updatePeopleError = (error) => ({
  type: types.UPDATE_PEOPLE_ERROR,
  payload: { error }
});

// Update people faces
export const updatePeopleFaces = (payload, callback) => ({
  type: types.UPDATE_PEOPLE_FACES_REQUEST,
  payload: { payload, callback }
});

export const updatePeopleFacesSuccess = (payload) => ({
  type: types.UPDATE_PEOPLE_FACES_SUCCESS,
  payload: { payload }
});

export const updatePeopleFacesError = (error) => ({
  type: types.UPDATE_PEOPLE_FACES_ERROR,
  payload: { error }
});

// Delete people
export const deletePeople = (payload, callback) => ({
  type: types.DELETE_PEOPLE_REQUEST,
  payload: { payload, callback }
});

export const deletePeopleSuccess = (payload) => ({
  type: types.DELETE_PEOPLE_SUCCESS,
  payload: { payload }
});

export const deletePeopleError = (error) => ({
  type: types.DELETE_PEOPLE_ERROR,
  payload: { error }
});

// Delete photo people: remove subjects_ids from photo
export const deletePhotoPeople = (payload, callback) => ({
  type: types.DELETE_PHOTO_PEOPLE_REQUEST,
  payload: { payload, callback }
});

export const deletePhotoPeopleSuccess = (payload) => ({
  type: types.DELETE_PHOTO_PEOPLE_SUCCESS,
  payload: { payload }
});

export const deletePhotoPeopleError = (error) => ({
  type: types.DELETE_PHOTO_PEOPLE_ERROR,
  payload: { error }
});

// Delete people photos
export const deletePeoplePhoto = (payload, callback) => ({
  type: types.DELETE_PEOPLE_PHOTO_REQUEST,
  payload: { payload, callback }
});

export const deletePeoplePhotoSuccess = (payload) => ({
  type: types.DELETE_PEOPLE_PHOTO_SUCCESS,
  payload: { payload }
});

export const deletePeoplePhotoError = (error) => ({
  type: types.DELETE_PEOPLE_PHOTO_ERROR,
  payload: { error }
});

// Set people details
export const setPeopleDetails = (payload) => ({
  type: types.SET_PEOPLE_DETAILS,
  payload: { payload }
});

// Set people mail list
export const setPeopleMail = (payload) => ({
  type: types.SET_PEOPLE_MAIL,
  payload: { payload }
});

// Set people photos count
export const setPeoplePhotosCount = (payload) => ({
  type: types.SET_PEOPLE_PHOTOS_COUNT,
  payload: { payload }
});

// Set people data labels
export const setPeopleLabels = (payload) => ({
  type: types.SET_PEOPLE_LABELS,
  payload: { payload }
});

// Create people lookup config
export const createPeopleLookupConfig = (payload, callback) => ({
  type: types.CREATE_PEOPLE_LOOKUP_CONFIG_REQUEST,
  payload: { payload, callback }
});

export const createPeopleLookupConfigSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_LOOKUP_CONFIG_SUCCESS,
  payload: { payload }
});

export const createPeopleLookupConfigError = (error) => ({
  type: types.CREATE_PEOPLE_LOOKUP_CONFIG_ERROR,
  payload: { error }
});

// Create people lookup validation
export const createPeopleLookupValidate = (payload, callback) => ({
  type: types.CREATE_PEOPLE_LOOKUP_VALIDATE_REQUEST,
  payload: { payload, callback }
});

export const createPeopleLookupValidateSuccess = (payload) => ({
  type: types.CREATE_PEOPLE_LOOKUP_VALIDATE_SUCCESS,
  payload: { payload }
});

export const createPeopleLookupValidateError = (error) => ({
  type: types.CREATE_PEOPLE_LOOKUP_VALIDATE_ERROR,
  payload: { error }
});

// Read people lookup config
export const getPeopleLookupConfig = (payload, callback) => ({
  type: types.GET_PEOPLE_LOOKUP_CONFIG_REQUEST,
  payload: { payload, callback }
});

export const getPeopleLookupConfigSuccess = (payload) => ({
  type: types.GET_PEOPLE_LOOKUP_CONFIG_SUCCESS,
  payload: { payload }
});

export const getPeopleLookupConfigError = (error) => ({
  type: types.GET_PEOPLE_LOOKUP_CONFIG_ERROR,
  payload: { error }
});

// Read people CSV colum config
export const getPeopleCsvColumnConfig = (callback) => ({
  type: types.GET_PEOPLE_CSV_COLUMN_CONFIG_REQUEST,
  payload: { callback }
});

export const getPeopleCsvColumnConfigSuccess = (payload) => ({
  type: types.GET_PEOPLE_CSV_COLUMN_CONFIG_SUCCESS,
  payload: { payload }
});

export const getPeopleCsvColumnConfigError = (error) => ({
  type: types.GET_PEOPLE_CSV_COLUMN_CONFIG_ERROR,
  payload: { error }
});

export const resetPeopleLookupValidate = () => ({
  type: types.RESET_PEOPLE_LOOKUP_VALIDATE
});

// Insights

// Read Insights Customers
export const getInsightsCustomersSummary = (payload) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_SUMMARY_REQUEST,
  payload: { payload }
});

export const getInsightsCustomersSummarySuccess = (payload) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getInsightsCustomersSummaryError = (error) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_SUMMARY_ERROR,
  payload: {
    error
  }
});

export const getInsightsAccessedSubjectsSummary = (payload) => ({
  type: types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_REQUEST,
  payload: { payload }
});

export const getInsightsAccessedSubjectsSummarySuccess = (payload) => ({
  type: types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getInsightsAccessedSubjectsSummaryError = (error) => ({
  type: types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_ERROR,
  payload: { error }
});

export const getInsightsYearbookSubjectsSummary = (payload) => ({
  type: types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_REQUEST,
  payload: { payload }
});

export const getInsightsYearbookSubjectsSummarySuccess = (payload) => ({
  type: types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getInsightsYearbookSubjectsSummaryError = (error) => ({
  type: types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_ERROR,
  payload: { error }
});

export const getInsightsCustomers = (payload) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_REQUEST,
  payload: { payload }
});

export const getInsightsCustomersSuccess = (payload) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_SUCCESS,
  payload: { payload }
});

export const getInsightsCustomersError = (error) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_ERROR,
  payload: {
    error
  }
});

// Read Insights Customer CSV
export const getInsightsCustomersCsv = (payload, callback) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_CSV_REQUEST,
  payload: { payload, callback }
});

export const getInsightsCustomersCsvSuccess = (jobInsightsCustomersCsv) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_CSV_SUCCESS,
  payload: { jobInsightsCustomersCsv }
});

export const getInsightsCustomersCsvError = (error) => ({
  type: types.GET_INSIGHTS_CUSTOMERS_CSV_ERROR,
  payload: {
    error
  }
});

// Read Insights Sales
export const getInsightsSalesSummary = (payload) => ({
  type: types.GET_INSIGHTS_SALES_SUMMARY_REQUEST,
  payload: { payload }
});

export const getInsightsSalesSummarySuccess = (payload) => ({
  type: types.GET_INSIGHTS_SALES_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getInsightsSalesSummaryError = (error) => ({
  type: types.GET_INSIGHTS_SALES_SUMMARY_ERROR,
  payload: { error }
});

export const getInsightsSales = (payload) => ({
  type: types.GET_INSIGHTS_SALES_REQUEST,
  payload: { payload }
});

export const getInsightsSalesSuccess = (payload) => ({
  type: types.GET_INSIGHTS_SALES_SUCCESS,
  payload: { payload }
});

export const getInsightsSalesError = (error) => ({
  type: types.GET_INSIGHTS_SALES_ERROR,
  payload: { error }
});

// Read Insights Sales CSV
export const getInsightsSalesCsv = (payload, callback) => ({
  type: types.GET_INSIGHTS_SALES_CSV_REQUEST,
  payload: { payload, callback }
});

export const getInsightsSalesCsvSuccess = (payload) => ({
  type: types.GET_INSIGHTS_SALES_CSV_SUCCESS,
  payload: { payload }
});

export const getInsightsSalesCsvError = (error) => ({
  type: types.GET_INSIGHTS_SALES_CSV_ERROR,
  payload: {
    error
  }
});

// Get Access Code
export const getAccessCode = (payload, callback) => ({
  type: types.GET_ACCESS_CODE_REQUEST,
  payload: { payload, callback }
});

export const getAccessCodeSuccess = (payload) => ({
  type: types.GET_ACCESS_CODE_SUCCESS,
  payload: { payload }
});

export const getAccessCodeError = (error) => ({
  type: types.GET_ACCESS_CODE_ERROR,
  payload: { error }
});

// FLyers

// Create flyer
export const createFlyer = (payload, callback) => ({
  type: types.CREATE_FLYER_REQUEST,
  payload: { payload, callback }
});

export const createFlyerSuccess = (payload) => ({
  type: types.CREATE_FLYER_SUCCESS,
  payload: { payload }
});

export const createFlyerError = (error) => ({
  type: types.CREATE_FLYER_ERROR,
  payload: { error }
});

// Create flyer email
export const createFlyerEmail = (payload, callback) => ({
  type: types.CREATE_FLYER_EMAIL_REQUEST,
  payload: { payload, callback }
});

export const createFlyerEmailSuccess = (payload) => ({
  type: types.CREATE_FLYER_EMAIL_SUCCESS,
  payload: { payload }
});

export const createFlyerEmailError = (error) => ({
  type: types.CREATE_FLYER_EMAIL_ERROR,
  payload: { error }
});

// Read flyer list
export const getFlyerList = (payload, callback) => ({
  type: types.GET_FLYER_LIST_REQUEST,
  payload: { payload, callback }
});

export const getFlyerListSuccess = (payload) => ({
  type: types.GET_FLYER_LIST_SUCCESS,
  payload: { payload }
});

export const getFlyerListError = (error) => ({
  type: types.GET_FLYER_LIST_ERROR,
  payload: { error }
});

// Read flyer
export const getFlyer = (payload, callback) => ({
  type: types.GET_FLYER_REQUEST,
  payload: { payload, callback }
});

export const getFlyerSuccess = (payload) => ({
  type: types.GET_FLYER_SUCCESS,
  payload: { payload }
});

export const getFlyerError = (error) => ({
  type: types.GET_FLYER_ERROR,
  payload: { error }
});

// Read flyer pdf
export const getFlyerPdf = (payload, callback) => ({
  type: types.GET_FLYER_PDF_REQUEST,
  payload: { payload, callback }
});

export const getFlyerPdfSuccess = (payload) => ({
  type: types.GET_FLYER_PDF_SUCCESS,
  payload: { payload }
});

export const getFlyerPdfError = (error) => ({
  type: types.GET_FLYER_PDF_ERROR,
  payload: { error }
});

// Update flyer
export const updateFlyer = (payload, callback) => ({
  type: types.UPDATE_FLYER_REQUEST,
  payload: { payload, callback }
});

export const updateFlyerSuccess = (payload) => ({
  type: types.UPDATE_FLYER_SUCCESS,
  payload: { payload }
});

export const updateFlyerError = (error) => ({
  type: types.UPDATE_FLYER_ERROR,
  payload: { error }
});

// Delete flyer
export const deleteFlyer = (payload, callback) => ({
  type: types.DELETE_FLYER_REQUEST,
  payload: { payload, callback }
});

export const deleteFlyerSuccess = (payload) => ({
  type: types.DELETE_FLYER_SUCCESS,
  payload: { payload }
});

export const deleteFlyerError = (error) => ({
  type: types.DELETE_FLYER_ERROR,
  payload: { error }
});

// Clean flyer
export const clearFlyer = (payload) => ({
  type: types.CLEAR_FLYER,
  payload: { payload }
});

// Admin

// Create admin sms
export const createAdminSms = (payload, callback) => ({
  type: types.CREATE_ADMIN_SMS_REQUEST,
  payload: { payload, callback }
});

export const createAdminSmsSuccess = (payload) => ({
  type: types.CREATE_ADMIN_SMS_SUCCESS,
  payload: { payload }
});

export const createAdminSmsError = (error) => ({
  type: types.CREATE_ADMIN_SMS_ERROR,
  payload: { error }
});

// Exports

// Create export
export const createJobExport = (payload, callback) => ({
  type: types.CREATE_JOB_EXPORT_REQUEST,
  payload: { payload, callback }
});

export const createJobExportSuccess = (payload) => ({
  type: types.CREATE_JOB_EXPORT_SUCCESS,
  payload: { payload }
});

export const createJobExportError = (error) => ({
  type: types.CREATE_JOB_EXPORT_ERROR,
  payload: { error }
});

// Create job export ready
export const createJobExportReady = (payload, callback) => ({
  type: types.CREATE_JOB_EXPORT_READY_REQUEST,
  payload: { payload, callback }
});

export const createJobExportReadySuccess = (payload) => ({
  type: types.CREATE_JOB_EXPORT_READY_SUCCESS,
  payload: { payload }
});

export const createJobExportReadyError = (error) => ({
  type: types.CREATE_JOB_EXPORT_READY_ERROR,
  payload: { error }
});

// Create job export assign subjects
export const createJobExportAssignSubjects = (payload, callback) => ({
  type: types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_REQUEST,
  payload: { payload, callback }
});

export const createJobExportAssignSubjectsSuccess = (payload) => ({
  type: types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_SUCCESS,
  payload: { payload }
});

export const createJobExportAssignSubjectsError = (error) => ({
  type: types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_ERROR,
  payload: { error }
});

// Create export prepare
export const createJobExportPrepare = (payload, callback) => ({
  type: types.CREATE_JOB_EXPORT_PREPARE_REQUEST,
  payload: { payload, callback }
});

export const createJobExportPrepareSuccess = (payload) => ({
  type: types.CREATE_JOB_EXPORT_PREPARE_SUCCESS,
  payload: { payload }
});

export const createJobExportPrepareError = (error) => ({
  type: types.CREATE_JOB_EXPORT_PREPARE_ERROR,
  payload: { error }
});

// Read job export list
export const getJobExportList = (payload, callback) => ({
  type: types.GET_JOB_EXPORT_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobExportListSuccess = (payload) => ({
  type: types.GET_JOB_EXPORT_LIST_SUCCESS,
  payload: { payload }
});

export const getJobExportListError = (error) => ({
  type: types.GET_JOB_EXPORT_LIST_ERROR,
  payload: { error }
});

// Read job export
export const getJobExport = (payload, callback) => ({
  type: types.GET_JOB_EXPORT_REQUEST,
  payload: { payload, callback }
});

export const getJobExportSuccess = (payload) => ({
  type: types.GET_JOB_EXPORT_SUCCESS,
  payload: { payload }
});

export const getJobExportError = (error) => ({
  type: types.GET_JOB_EXPORT_ERROR,
  payload: { error }
});

// Read job export items
export const getJobExportItems = (payload, callback) => ({
  type: types.GET_JOB_EXPORT_ITEMS_REQUEST,
  payload: { payload, callback }
});

export const getJobExportItemsSuccess = (payload) => ({
  type: types.GET_JOB_EXPORT_ITEMS_SUCCESS,
  payload: { payload }
});

export const getJobExportItemsError = (error) => ({
  type: types.GET_JOB_EXPORT_ITEMS_ERROR,
  payload: { error }
});

// Read export people field options mapping
export const getJobExportPeopleFieldOptions = (payload, callback) => ({
  type: types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_REQUEST,
  payload: { payload, callback }
});

export const getJobExportPeopleFieldOptionsSuccess = (payload) => ({
  type: types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_SUCCESS,
  payload: { payload }
});

export const getJobExportPeopleFieldOptionsError = (error) => ({
  type: types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_ERROR,
  payload: { error }
});

// Update job export
export const updateJobExport = (payload, callback) => ({
  type: types.UPDATE_JOB_EXPORT_REQUEST,
  payload: { payload, callback }
});

export const updateJobExportSuccess = (payload) => ({
  type: types.UPDATE_JOB_EXPORT_SUCCESS,
  payload: { payload }
});

export const updateJobExportError = (error) => ({
  type: types.UPDATE_JOB_EXPORT_ERROR,
  payload: { error }
});

// Actiom Cable Update export prepare
export const updateJobExportFromCable = (payload) => ({
  type: types.UPDATE_JOB_EXPORT_SUCCESS,
  payload: { payload }
});

// Update job export item
export const updateJobExportItem = (payload, callback) => ({
  type: types.UPDATE_JOB_EXPORT_ITEM_REQUEST,
  payload: { payload, callback }
});

export const updateJobExportItemSuccess = (payload) => ({
  type: types.UPDATE_JOB_EXPORT_ITEM_SUCCESS,
  payload: { payload }
});

export const updateJobExportItemError = (error) => ({
  type: types.UPDATE_JOB_EXPORT_ITEM_ERROR,
  payload: { error }
});

// Actiom Cable Update job export item
export const updateJobExportItemFromCable = (payload) => ({
  type: types.UPDATE_JOB_EXPORT_ITEM_SUCCESS,
  payload: { payload }
});

// Delete job export
export const deleteJobExport = (payload, callback) => ({
  type: types.DELETE_JOB_EXPORT_REQUEST,
  payload: { payload, callback }
});

export const deleteJobExportSuccess = (payload) => ({
  type: types.DELETE_JOB_EXPORT_SUCCESS,
  payload: { payload }
});

export const deleteJobExportError = (error) => ({
  type: types.DELETE_JOB_EXPORT_ERROR,
  payload: { error }
});

// Delete job export items
export const deleteJobExportItem = (payload, callback) => ({
  type: types.DELETE_JOB_EXPORT_ITEM_REQUEST,
  payload: { payload, callback }
});

export const deleteJobExportItemSuccess = (payload) => ({
  type: types.DELETE_JOB_EXPORT_ITEM_SUCCESS,
  payload: { payload }
});

export const deleteJobExportItemError = (error) => ({
  type: types.DELETE_JOB_EXPORT_ITEM_ERROR,
  payload: { error }
});

// Knockouts

// Create knockout
export const createJobKnockout = (payload, callback) => ({
  type: types.CREATE_JOB_KNOCKOUT_REQUEST,
  payload: { payload, callback }
});

export const createJobKnockoutSuccess = (payload) => ({
  type: types.CREATE_JOB_KNOCKOUT_SUCCESS,
  payload: { payload }
});

export const createJobKnockoutError = (error) => ({
  type: types.CREATE_JOB_KNOCKOUT_ERROR,
  payload: { error }
});

// Create job knockout ready
export const createJobKnockoutReady = (payload, callback) => ({
  type: types.CREATE_JOB_KNOCKOUT_READY_REQUEST,
  payload: { payload, callback }
});

export const createJobKnockoutReadySuccess = (payload) => ({
  type: types.CREATE_JOB_KNOCKOUT_READY_SUCCESS,
  payload: { payload }
});

export const createJobKnockoutReadyError = (error) => ({
  type: types.CREATE_JOB_KNOCKOUT_READY_ERROR,
  payload: { error }
});

// Create job knockout prepare
export const createJobKnockoutPrepare = (payload, callback) => ({
  type: types.CREATE_JOB_KNOCKOUT_PREPARE_REQUEST,
  payload: { payload, callback }
});

export const createJobKnockoutPrepareSuccess = (payload) => ({
  type: types.CREATE_JOB_KNOCKOUT_PREPARE_SUCCESS,
  payload: { payload }
});

export const createJobKnockoutPrepareError = (error) => ({
  type: types.CREATE_JOB_KNOCKOUT_PREPARE_ERROR,
  payload: { error }
});

// Create job knockout transfer
export const createJobKnockoutTransfer = (payload, callback) => ({
  type: types.CREATE_JOB_KNOCKOUT_TRANSFER_REQUEST,
  payload: { payload, callback }
});

export const createJobKnockoutTransferSuccess = (payload) => ({
  type: types.CREATE_JOB_KNOCKOUT_TRANSFER_SUCCESS,
  payload: { payload }
});

export const createJobKnockoutTransferError = (error) => ({
  type: types.CREATE_JOB_KNOCKOUT_TRANSFER_ERROR,
  payload: { error }
});

// Read job knockout list
export const getJobKnockoutList = (payload, callback) => ({
  type: types.GET_JOB_KNOCKOUT_LIST_REQUEST,
  payload: { payload, callback }
});

export const getJobKnockoutListSuccess = (payload) => ({
  type: types.GET_JOB_KNOCKOUT_LIST_SUCCESS,
  payload: { payload }
});

export const getJobKnockoutListError = (error) => ({
  type: types.GET_JOB_KNOCKOUT_LIST_ERROR,
  payload: { error }
});

// Read job knockout
export const getJobKnockout = (payload, callback) => ({
  type: types.GET_JOB_KNOCKOUT_REQUEST,
  payload: { payload, callback }
});

export const getJobKnockoutSuccess = (payload) => ({
  type: types.GET_JOB_KNOCKOUT_SUCCESS,
  payload: { payload }
});

export const getJobKnockoutError = (error) => ({
  type: types.GET_JOB_KNOCKOUT_ERROR,
  payload: { error }
});

// Update job knockout
export const updateJobKnockout = (payload, callback) => ({
  type: types.UPDATE_JOB_KNOCKOUT_REQUEST,
  payload: { payload, callback }
});

export const updateJobKnockoutSuccess = (payload) => ({
  type: types.UPDATE_JOB_KNOCKOUT_SUCCESS,
  payload: { payload }
});

export const updateJobKnockoutError = (error) => ({
  type: types.UPDATE_JOB_KNOCKOUT_ERROR,
  payload: { error }
});

// Action Cable Update knockout
export const updateJobKnockoutFromCable = (payload) => ({
  type: types.UPDATE_JOB_KNOCKOUT_SUCCESS,
  payload: { payload }
});

// Delete job knockout
export const deleteJobKnockout = (payload, callback) => ({
  type: types.DELETE_JOB_KNOCKOUT_REQUEST,
  payload: { payload, callback }
});

export const deleteJobKnockoutSuccess = (payload) => ({
  type: types.DELETE_JOB_KNOCKOUT_SUCCESS,
  payload: { payload }
});

export const deleteJobKnockoutError = (error) => ({
  type: types.DELETE_JOB_KNOCKOUT_ERROR,
  payload: { error }
});

// Update knockout dropzone global state
export const updateKnockoutDropzone = (payload) => ({
  type: types.UPDATE_KNOCKOUT_DROPZONE,
  payload: { payload }
});

// Update knockout upload
export const updateKnockoutUploadFromCable = (payload) => ({
  type: types.UPDATE_KNOCKOUT_UPLOAD_FROM_CABLE,
  payload: { payload }
});

// Knockout Items
// Create knockout items
export const createKnockoutItem = (payload, callback) => ({
  type: types.CREATE_JOB_KNOCKOUT_ITEM,
  payload: { payload, callback }
});

// Read knockout-items
export const getJobKnockoutItems = (payload, callback) => ({
  type: types.GET_JOB_KNOCKOUT_ITEMS_REQUEST,
  payload: { payload, callback }
});

export const getJobKnockoutItemsSuccess = (payload) => ({
  type: types.GET_JOB_KNOCKOUT_ITEMS_SUCCESS,
  payload: { payload }
});

export const getJobKnockoutItemsError = (error) => ({
  type: types.GET_JOB_KNOCKOUT_ITEMS_ERROR,
  payload: { error }
});

// Delete knockout-items
export const deleteJobKnockoutItems = (payload, callback) => ({
  type: types.DELETE_JOB_KNOCKOUT_ITEMS_REQUEST,
  payload: { payload, callback }
});

export const deleteJobKnockoutItemsSuccess = (payload) => ({
  type: types.DELETE_JOB_KNOCKOUT_ITEMS_SUCCESS,
  payload: { payload }
});

export const deleteJobKnockoutItemsError = (error) => ({
  type: types.DELETE_JOB_KNOCKOUT_ITEMS_ERROR,
  payload: { error }
});

// Marketing Messages
// Read General Messages
export const getJobMarketingMessages = (payload, callback) => ({
  type: types.GET_JOB_MARKETING_MESSAGES_REQUEST,
  payload: { payload, callback }
});

export const getJobMarketingMessagesSuccess = (payload) => ({
  type: types.GET_JOB_MARKETING_MESSAGES_SUCCESS,
  payload: { payload }
});

export const getJobMarketingMessagesError = (error) => ({
  type: types.GET_JOB_MARKETING_MESSAGES_ERROR,
  payload: { error }
});

// Capture QR
export const getJobQrSessions = (payload, callback) => ({
  type: types.GET_JOB_QR_SESSIONS_REQUEST,
  payload: { payload, callback }
});

export const getJobQrSessionsSuccess = (payload) => ({
  type: types.GET_JOB_QR_SESSIONS_SUCCESS,
  payload: { payload }
});

export const getJobQrSessionsError = (error) => ({
  type: types.GET_JOB_QR_SESSIONS_ERROR,
  payload: { error }
});

export const getJobQrCodeBySession = (payload, callback) => ({
  type: types.GET_JOB_QR_CODE_BY_SESSION_REQUEST,
  payload: { payload, callback }
});

export const getJobQrCodeBySessionSuccess = (payload) => ({
  type: types.GET_JOB_QR_CODE_BY_SESSION_SUCCESS,
  payload: { payload }
});

export const getJobQrCodeBySessionError = (error) => ({
  type: types.GET_JOB_QR_CODE_BY_SESSION_ERROR,
  payload: { error }
});

export const createJobQrCodeResolution = (payload, callback) => ({
  type: types.CREATE_JOB_QR_CODE_RESOLUTION_REQUEST,
  payload: { payload, callback }
});

export const createJobQrCodeResolutionSuccess = (payload) => ({
  type: types.CREATE_JOB_QR_CODE_RESOLUTION_SUCCESS,
  payload: { payload }
});

export const createJobQrCodeResolutionError = (error) => ({
  type: types.CREATE_JOB_QR_CODE_RESOLUTION_ERROR,
  payload: { error }
});

export const deleteJobQrSessionPhotos = (payload, callback) => ({
  type: types.DELETE_JOB_QR_SESSION_PHOTOS_REQUEST,
  payload: { payload, callback }
});

export const deleteJobQrSessionPhotosSuccess = (payload) => ({
  type: types.DELETE_JOB_QR_SESSION_PHOTOS_SUCCESS,
  payload: { payload }
});

export const deleteJobQrSessionPhotosError = (error) => ({
  type: types.DELETE_JOB_QR_SESSION_PHOTOS_ERROR,
  payload: { error }
});

export const createJobQrIgnoreErrors = (payload, callback) => ({
  type: types.CREATE_JOB_QR_IGNORE_ERRORS_REQUEST,
  payload: { payload, callback }
});

export const createJobQrIgnoreErrorsSuccess = (payload) => ({
  type: types.CREATE_JOB_QR_IGNORE_ERRORS_SUCCESS,
  payload: { payload }
});

export const createJobQrIgnoreErrorsError = (error) => ({
  type: types.CREATE_JOB_QR_IGNORE_ERRORS_ERROR,
  payload: { error }
});

export const createJobQrSessionMatching = (payload, callback) => ({
  type: types.CREATE_JOB_QR_SESSION_MATCHING_REQUEST,
  payload: { payload, callback }
});

export const createJobQrSessionMatchingSuccess = (payload) => ({
  type: types.CREATE_JOB_QR_SESSION_MATCHING_SUCCESS,
  payload: { payload }
});

export const createJobQrSessionMatchingError = (error) => ({
  type: types.CREATE_JOB_QR_SESSION_MATCHING_ERROR,
  payload: { error }
});
