import PropTypes from 'prop-types';
import { useState, useEffect, memo } from 'react';

// Plugins
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import {
  createPeopleAssociation,
  getPeopleList,
  getPeopleFacesList,
  getPeoplePhotoFacesList,
  deletePeoplePhoto,
  createPeopleAssociationPhotos,
  updatePeopleFaces
} from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import imageScaling from '@/utils/imageScaling';
import subjectsFilterOptions from '@/utils/subjectsFilterOptions';

// Styles
import './style.css';

const Match = memo(function Match({
  jobId,
  peopleQuery,
  filterPeople,
  photoSelected,
  ignoredSelected,
  allPhotosSelected,
  actionType,
  onShowMatchToggle,
  onPhotosFilter,
  onPhotosPeopleFilter,
  onPhotoSelectClear,
  onFaceSelectClear
}) {
  const dispatch = useDispatch();

  const {
    job: { photo_stats: photoStats },
    photos: { list: photosList, requesting: photosRequesting, pagination },
    people: { list: people, fields },
    faces: { list: faces, requesting: facesRequesting },
    requesting
  } = useSelector((state) => state.jobs);

  const [photos, setPhotos] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState({ image_url: '', image_filename: '' });
  const [faceSelected, setFaceSelected] = useState('photo');
  const [peopleSelected, setPeopleSelected] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filterBy, setFilterBy] = useState({ label: 'All subjects', value: 'all' });
  const [filterGroup, setFilterGroup] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [peopleList, setPeopleList] = useState([]);

  const filterOptions = subjectsFilterOptions;

  const handleMatchClose = () => {
    handleMatchCancel();

    onPhotoSelectClear();
    onFaceSelectClear();
    onPhotoSelectClear();
    onPhotosPeopleFilter(filterPeople);

    dispatch(
      getPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }, ({ data }) =>
        setGroups(data.filter((person) => person.id !== filterPeople.id))
      )
    );

    // Refresh photo gallery list if current filter is set to unmatched
    if (peopleQuery === 'unmatched') onPhotosFilter(peopleQuery);
  };

  const handleMatchCancel = () => {
    setFilterData([]);
    setFilterGroup('');
    setPeopleSelected([]);
    setFaceSelected('photo');
    setCurrentPhoto({ image_url: '', image_filename: '' });

    onShowMatchToggle();
    if (ignoredSelected) dispatch(getPeopleFacesList({ id: jobId, ignored: true }));
  };

  const handleFilterGroupChange = (select = '') => {
    setFilterData([]);
    setFilterBy({ label: 'All subjects', value: 'all' });

    if (select !== null) {
      setFilterGroup(select);
    } else {
      setFilterGroup('');
    }
  };

  const handleFilterDataChange = (select = []) => {
    setFilterData(select || []);
  };

  const handleFilterByChange = (filter) => {
    setFilterBy(filter);
  };

  const handleFilterBy = () => {
    let filteredPeople = people;

    if (filterData.length) {
      filteredPeople = people.filter((person) => filterData.find((data) => data.value === person[filterGroup?.value]));
    }

    switch (filterBy.value) {
      case 'with_ref':
        filteredPeople = filteredPeople.filter((person) => person.session_photo_url);
        break;
      case 'without_ref':
        filteredPeople = filteredPeople.filter((person) => !person.session_photo_url);
        break;
      case 'has_photos':
        filteredPeople = filteredPeople.filter((person) => person.photos_count > 0);
        break;
      case 'has_no_photos':
        filteredPeople = filteredPeople.filter((person) => person.photos_count === 0);
        break;
      case 'with_ref_no_photos':
        filteredPeople = filteredPeople.filter((person) => person.session_photo_url && person.photos_count === 0);
        break;
      case 'absent':
        filteredPeople = filteredPeople.filter((person) => person.absent);
        break;
      default:
        break;
    }

    setPeopleList(filteredPeople);
  };

  const handleFaceSelect = (faceId) => {
    const faceFound = faces.find((face) => face.id === faceId);

    if (faceFound && faceFound.matched) {
      const { subjects } = faceFound;

      setPeopleList([...subjects, ...people.filter((subject) => !subjects.some((item) => item.id === subject.id))]);
      setPeopleSelected(subjects.length ? subjects[0].id : []);
    } else {
      setPeopleList(people);
      setPeopleSelected([]);
    }

    setFilterData([]);
    setFilterGroup('');
    setFaceSelected(faceId);
  };

  const handlePeopleSelect = (person) => {
    if (faceSelected === 'photo') {
      setPeopleSelected(
        peopleSelected.some((select) => select === person) ? peopleSelected.filter((select) => select !== person) : [...peopleSelected, person]
      );
    } else {
      setPeopleSelected([person]);
    }
  };

  const handlePeopleSelectAll = () => {
    if (peopleList.length !== peopleSelected.length) {
      setPeopleSelected(peopleList.map((person) => person.id));
    } else {
      setPeopleSelected([]);
    }
  };

  const handleNextFace = (includeIgnored = false) => {
    setPeopleSelected([]);

    const nextFaceIndex = faces.findIndex((face) => (includeIgnored ? face.ignored : !face.matched && !face.ignored) && face.id !== faceSelected);

    if (faceSelected === 'photo' || nextFaceIndex < 0) {
      handleMatchClose();
    } else {
      handleFaceSelect(faces[nextFaceIndex]['id']);
    }
  };

  const handleIgnoreFace = () => {
    setPeopleSelected([]);
    dispatch(
      updatePeopleFaces({ jobId, id: faceSelected, ignored: faces.some((face) => face.id === faceSelected && face.ignored) ? false : true }, () => {
        handleNextFace(ignoredSelected ? true : false);
      })
    );
  };

  const handleMove = () => {
    const shouldPerformAll = allPhotosSelected && (!filterPeople || Object.keys(filterPeople).length === 0) && pagination.total === photoStats.photos;

    if (faceSelected === 'photo') {
      dispatch(
        createPeopleAssociationPhotos({ id: jobId, photo_ids: photoSelected, subject_ids: peopleSelected, perform_all: shouldPerformAll }, () => {
          dispatch(deletePeoplePhoto({ id: filterPeople.id, photo_ids: photoSelected, perform_all: shouldPerformAll }, () => handleMatchClose()));
        })
      );
    } else {
      dispatch(
        createPeopleAssociation({ id: peopleSelected, photo_face_ids: [faceSelected] }, () => {
          dispatch(deletePeoplePhoto({ id: filterPeople.id, photo_ids: photoSelected }, () => handleNextFace()));
        })
      );
    }
  };

  const handleMatch = () => {
    const shouldPerformAll = allPhotosSelected && (!filterPeople || Object.keys(filterPeople).length === 0) && pagination.total === photoStats.photos;

    if (faceSelected === 'photo') {
      dispatch(
        createPeopleAssociationPhotos({ id: jobId, photo_ids: photoSelected, subject_ids: peopleSelected, perform_all: shouldPerformAll }, () =>
          handleMatchClose()
        )
      );
    } else {
      dispatch(
        createPeopleAssociation({ id: peopleSelected, photo_face_ids: [faceSelected] }, () => {
          if (ignoredSelected) {
            dispatch(
              getPeoplePhotoFacesList({ id: photoSelected[0] }, () => {
                faceSelected.ignored ? handleIgnoreFace() : handleNextFace();
              })
            );
          } else {
            handleNextFace();
          }
        })
      );
    }
  };

  useEffect(() => {
    setPhotos(photosList);

    dispatch(
      getPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }, ({ data }) => {
        setGroups(data.filter((person) => person.id !== filterPeople.id));
        setPeopleList(data);
      })
    );
  }, []);

  useEffect(() => {
    if (photos.length > 0 && photoSelected.length === 1) {
      const photoFound = photos.find((photo) => photo.id === photoSelected[0]);

      if (photoFound) {
        setCurrentPhoto(photoFound);
      }

      if (actionType === 'match') {
        dispatch(
          getPeoplePhotoFacesList({ id: photoSelected[0] }, () => {
            if (ignoredSelected) {
              setFaceSelected(ignoredSelected);
            }
          })
        );
      }
    }
  }, [photos]);

  useEffect(() => {
    if (filterGroup?.value && filterGroup?.value.includes('matched')) {
      handleFilterBy();
    }
  }, [filterGroup]);

  useEffect(() => {
    handleFilterBy();
  }, [filterData, filterBy]);

  return (
    <aside className="modal animate job-match">
      <div className="modal__box modal__box--secondary modal__box--xxlarge">
        <button className="button button--action modal__close" name="close" type="button" onClick={handleMatchCancel}>
          <i className="icon-close"></i>
        </button>
        <main className="flex modal__content">
          <section className="flex-4 flex-12-md modal__content-section">
            {photoSelected.length > 1 ? (
              <>
                <h1>Photos Selected</h1>
                <ul className="grid gap-10 job-match__photos-list">
                  {photoSelected.map((photo, index) => {
                    const image = photos.find((item) => item.id === photo) || {};

                    return (
                      <li key={index}>
                        <img className="job-match__image" src={imageScaling({ url: image.image_url, size: 'small' })} alt={image.image_filename} />
                      </li>
                    );
                  })}
                </ul>
                <footer className="panel panel--lean panel--round panel--gray job-match__face-footer">
                  <div className="flex middle nowrap">
                    <figure className="job-match__face-avatar">
                      <i className="icon-logo-placeholder icon-logo-placeholder--small" />
                    </figure>
                    <small>Select a subject on the right to {actionType === 'match' ? 'match' : 'move'} these photos.</small>
                  </div>
                </footer>
              </>
            ) : (
              <>
                <h3 className="text--truncate">{currentPhoto.image_filename}</h3>
                <figure className="flex center middle job-match__figure">
                  <img className="job-match__image" src={imageScaling({ url: currentPhoto.image_url, size: 'medium' })} alt={currentPhoto.image_filename} />
                </figure>

                {!photosRequesting && faces.length > 0 && (
                  <>
                    {faces.length > 1 ? (
                      <>
                        {actionType === 'match' && (
                          <aside className="panel panel--lean panel--round panel--gray job-match__face-info">
                            <div className="flex middle nowrap">
                              <figure className="job-match__face-avatar">
                                <i className="icon-people-silhouette-avatar-small" />
                              </figure>
                              <small>The faces below have been found in this photo. Select a face and match it to a subject on the right.</small>
                            </div>
                          </aside>
                        )}

                        <ul className="grid gap-10 job-match__face-list animate">
                          {faces
                            .filter((face) => (actionType === 'move' ? face.matched : ignoredSelected ? face.ignored : true))
                            .map((face) => (
                              <li
                                className={`job-match__face-item ${face.matched ? 'job-match__face-item--matched' : ''} ${
                                  face.ignored ? 'job-match__face-item--ignored' : ''
                                } ${faceSelected === face.id ? 'job-match__face-item--active' : ''}`}
                                key={face.id}
                                onClick={() => handleFaceSelect(face.id)}
                              >
                                <figure
                                  className={`flex middle center job-match__face-figure  ${
                                    facesRequesting && faceSelected === face.id ? 'job-match__face-figure--processing' : ''
                                  }`}
                                >
                                  <LazyLoadImage
                                    className={`job-match__gallery-face-image ${
                                      face.matched || face.ignored ? 'job-match__gallery-face-image--disabled' : ''
                                    } `}
                                    src={`${face.base_image_url}&rect=${face.crop_rect.x},${face.crop_rect.y},${face.crop_rect.w},${face.crop_rect.h}`}
                                    alt={face.photo_id}
                                    height={50}
                                    width={50}
                                    draggable="false"
                                  />
                                </figure>
                              </li>
                            ))}
                          <li
                            className={`job-match__face-item ${faceSelected === 'photo' ? 'job-match__face-item--active' : ''}`}
                            onClick={() => handleFaceSelect('photo')}
                          >
                            <figure className="flex middle center job-match__face-figure">
                              <figcaption>Everyone</figcaption>
                            </figure>
                          </li>
                        </ul>

                        <footer className="panel panel--lean panel--round panel--gray job-match__face-footer">
                          <small>
                            Missing faces above? Select <strong>Everyone</strong> and we’ll match every face in the photo to the subjects you select.
                          </small>
                        </footer>
                      </>
                    ) : (
                      <aside className="panel panel--lean panel--round panel--gray job-match__face-info">
                        <div className="flex middle nowrap">
                          <figure className="job-match__face-avatar">
                            <i className="icon-logo-placeholder icon-logo-placeholder--small" />
                          </figure>
                          <small>Select a subject on the right to {actionType === 'match' ? 'match' : 'move'} this photo.</small>
                        </div>
                      </aside>
                    )}
                  </>
                )}
              </>
            )}
          </section>

          <section className="flex-8 flex-12-md modal__content-section">
            <header>
              <h3>People</h3>
              {(filterGroup || people.length > 2) && (
                <form className={`flex flex-row ${people.length ? '' : 'transparent'}`}>
                  <fieldset className="flex flex-6 gap-10 mb-10">
                    <Select
                      className="select select--capitalize"
                      classNamePrefix="select"
                      placeholder="Search by"
                      isClearable={true}
                      value={filterGroup}
                      options={[
                        ...Object.keys(fields)
                          .filter((key) => key !== 'image_name' && key !== 'absent')
                          .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }))
                      ]}
                      onChange={handleFilterGroupChange}
                    />
                    <Select
                      className="select select--capitalize"
                      classNamePrefix="select"
                      placeholder="Filter by"
                      value={filterBy}
                      options={filterOptions}
                      onChange={handleFilterByChange}
                    />
                  </fieldset>
                  <fieldset className="flex-6 mb-10">
                    <Select
                      className="select select--nomargin job-match__select-data"
                      classNamePrefix="select"
                      isMulti={true}
                      isClearable={true}
                      placeholder="Select data..."
                      isDisabled={filterGroup === '' || filterGroup?.value === '' || (filterGroup?.value && filterGroup.value.includes('matched'))}
                      value={filterData}
                      options={[...new Set(groups.map((group) => JSON.stringify({ value: group[filterGroup.value], label: group[filterGroup.value] })))]
                        .map((group) => JSON.parse(group))
                        .filter((group) => group.value)}
                      onChange={handleFilterDataChange}
                    />
                  </fieldset>
                </form>
              )}
            </header>
            {peopleList.length ? (
              <>
                <div className="flex end">
                  <button className={`button button--lean ${faceSelected === 'photo' ? '' : 'transparent'}`} onClick={handlePeopleSelectAll}>
                    {peopleList.length !== peopleSelected.length ? 'Select All' : 'Clear All'}
                  </button>
                </div>
                <ul className="grid gap-10 job-match__person-list">
                  {peopleList
                    .filter((person) => person.id !== filterPeople.id)
                    .map((person) => (
                      <li
                        className={`job-match__person-item ${peopleSelected.includes(person.id) ? 'job-match__person-item--active' : ''}`}
                        key={person.id}
                        onClick={() => handlePeopleSelect(person.id)}
                      >
                        <figure className="flex middle center job-match__person-figure">
                          <LazyLoadImage
                            className="job-match__gallery-person-image"
                            src={imageScaling({ url: person.session_photo_url, size: 'small' })}
                            alt={person.session_photo_filename}
                            height={208}
                            width={140}
                            draggable="false"
                          />
                          <figcaption className="text--truncate job-match__gallery-person-figcaption">{`${person.first_name} ${person.last_name}`}</figcaption>
                        </figure>
                      </li>
                    ))}
                </ul>
              </>
            ) : (
              <aside className="panel panel--tall flex center middle">
                <h4 className="text--center">There are no recommended photos.</h4>
              </aside>
            )}
          </section>
        </main>
        <footer className="flex end modal__footer modal__footer--secondary button-group">
          {actionType === 'match' && photoSelected.length === 1 && faces.length > 1 && (
            <button
              className="button button--medium button--warning"
              name="ignore"
              type="button"
              onClick={handleIgnoreFace}
              disabled={faceSelected === 'photo'}
            >
              {faces.some((face) => face.id === faceSelected && face.ignored) ? 'Unignore' : 'Ignore'}
            </button>
          )}
          {actionType === 'match' ? (
            <button
              className="button button--medium"
              name="match"
              type="button"
              onClick={handleMatch}
              disabled={requesting || !peopleSelected.length}
              data-loading={requesting}
            >
              Match
            </button>
          ) : (
            <button
              className="button button--medium"
              name="match"
              type="button"
              onClick={handleMove}
              disabled={requesting || !peopleSelected.length}
              data-loading={requesting}
            >
              Move
            </button>
          )}
          <button className="button button--lean button--medium" name="close" type="button" onClick={handleMatchClose}>
            Close
          </button>
        </footer>
      </div>
    </aside>
  );
});

Match.propTypes = {
  jobId: PropTypes.string.isRequired,
  photoSelected: PropTypes.array.isRequired,
  filterPeople: PropTypes.object.isRequired,
  actionType: PropTypes.string.isRequired,
  allPhotosSelected: PropTypes.bool
};

Match.defaultProps = {
  jobId: '',
  photoSelected: [],
  filterPeople: {},
  actionType: '',
  allPhotosSelected: false
};

export default Match;
