import * as api from '@/utils/http';

const FORM_STACK_FORM_ID = import.meta.env.VITE_FORM_STACK_FORM_ID;

export const postOrderResolutionForm = (payload) => {
  const endpoint = 'formstack/submit-form';
  const args = {
    form_fields: payload,
    form_id: FORM_STACK_FORM_ID
  };

  return api.post(endpoint, args);
};
